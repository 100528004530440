






















































import { Component, PropSync, Vue } from 'vue-property-decorator';
import DeleteCustomInvestorGoalConfirmViewModel
  from '@/vue-app/view-models/components/goals-dashboard/delete-goals/delete-custom-investor-goal-confirm-view-model';

@Component({ name: 'DeleteCustomInvestorGoalConfirm' })
export default class DeleteCustomInvestorGoalConfirm extends Vue {
  delete_custom_goal_confirm_model = Vue.observable(
    new DeleteCustomInvestorGoalConfirmViewModel(this),
  );
}
