import Vue from 'vue';
import TYPES from '@/types';

// Application
import DeleteCustomInvestorGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/delete-custom-investor-goal-command';
import DeleteCustomInvestorGoalStateManagerService
  from '@/modules/flagship/custom-investor-goal/application/service/delete-custom-investor-goal-state-manager-service';

// Domain
import DeleteCustomInvestorGoalState
  from '@/modules/flagship/custom-investor-goal/domain/state/delete-custom-investor-goal-state';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class DeleteCustomInvestorGoalConfirmViewModel {
  @Inject(TYPES.DELETE_CUSTOM_INVESTOR_GOAL_COMMAND)
  private readonly delete_custom_investor_goal_cmd!: DeleteCustomInvestorGoalCommand;

  @Inject(TYPES.DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER_SERVICE)
  private readonly state_manager_service!: DeleteCustomInvestorGoalStateManagerService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.delete-custom-investor-goal-confirm';

  view!: Vue;

  state: DeleteCustomInvestorGoalState;

  constructor(view: Vue, state?: DeleteCustomInvestorGoalState) {
    this.view = view;
    this.state = state || this.state_manager_service.get();
  }

  is_disabled = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  deleteCustomInvestorGoal = async () => {
    try {
      this.state.is_loading = true;
      this.is_disabled = true;
      await this.delete_custom_investor_goal_cmd.execute(
        this.state.custom_investor_goal.custom_investor_goal_id,
      );
      this.view.$emit('nextStep');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.delete_custom_investor_goal'));
    } finally {
      this.state.is_loading = false;
      this.is_disabled = false;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    await this.deleteCustomInvestorGoal();
  }
}
